import React, {useEffect, useState} from 'react';
import kasperskyBannerTR from "../images/tmp/kaspersky-promo-turkey.png"
import kasperskyBannerKO from "../images/tmp/kaspersky-promo-korea.png"

const kasperskyProLinkTR = 'https://store.bittorrent.com/849/purl-utprokasperskyavtr'
const kasperskyProVPNLinkTR = 'https://store.bittorrent.com/849/purl-utprovpnkasperskyavtr'
const kasperskyProLinkKO = 'https://store.bittorrent.com/849/purl-utprokasperskyiskr'
const kasperskyProVPNLinkKO = 'https://store.bittorrent.com/849/purl-utprovpnkasperskyiskr'

const KasperskyHook = (locale) => {

	const [isTR, setIsTR] = useState(false);
	const [isKO, setIsKO] = useState(false);
	const [kasperskyBanner, setKasperskyBanner] = useState([]);
  return (
  	{
  		isTR,
  		isKO,
  		kasperskyBanner
  	}
  )
}

//Higher-Order Component(HOC)
//create new component so KasperskyHook can be used in class component
function KasperskyHOC(Component) {
  return function WrappedComponent(props) {
    const myHookValue = KasperskyHook(props.pageContext.locale);
    return <Component {...props} myHookValue={myHookValue} />;
  }
}

export {KasperskyHook, kasperskyProLinkTR, kasperskyProVPNLinkTR, kasperskyProLinkKO, kasperskyProVPNLinkKO, KasperskyHOC}